import React from 'react'
import PropTypes from 'prop-types'
import SanityImage from 'gatsby-plugin-sanity-image-alt'
import loadable from '@loadable/component'
import { useLightboxStore } from '../../stores/lightbox'
import { useSanityImageShape } from '../../helpers/useSanityImageShape'


import PhotosCta from '../../svg/photos-cta.svg'

const Shape = loadable(() => import('./Shape'))
const BookingSearch = loadable(() => import('../BookingSearch'))

const GalleryDesktop = ({ bgImg, gallery, booking }) => {

  const language = process.env.GATSBY_SITE_LANGUAGE
  const { showGallery, setShowGallery, setImages } = useLightboxStore()

  let featuredImage
  // Coded by Shane. I don't like it but it works.
  if (bgImg !== null) {
    if (typeof bgImg.asset !== 'undefined') {
      featuredImage = bgImg
    } else {
      featuredImage = gallery.images.find(image => image.featured)
    }
  } else {
    featuredImage = gallery.images.find(image => image.featured)
  }

  const totalImages = gallery?.images.length

  const triggerGallery = () => {
    setShowGallery(!showGallery)
    setImages(gallery.images)
  }

  const featuredImageShaped = useSanityImageShape(featuredImage)

  return (
    <>
      <section className="relative flex items-end w-full h-heading-m md:h-heading-d z-1">
        <div className="absolute inset-0 w-full h-full text-gray-100">
          <SanityImage
            {...featuredImageShaped}
            className="object-cover w-full h-full"
            width={1920}
            height={1080}
            alt={`${featuredImage.alt}`}
          />
          <Shape heading />
          <div className="absolute inset-0 w-full h-full bg-black bg-opacity-35" />
        </div>
        {totalImages && (
          <div className="absolute inset-0 z-20 flex items-end w-full h-full">
            <div className="grid grid-cols-12 md:container md:gap-8 md:mx-auto md:py-16">
              <button
                type="button"
                className="flex items-center justify-center col-span-4 col-start-9 p-2 font-bold text-white bg-blue-500 border border-white rounded lg:col-span-3 xl:col-span-2 lg:col-start-10 xl:col-start-11 bg-opacity-80 text-14"
                onClick={triggerGallery}
              >
                {language === 'en' ? (
                  <>
                    View {totalImages} photo{totalImages === 1 ? '' : 's'}
                  </>
                ) : (
                  <>
                    Visualizza {totalImages} foto
                  </>
                )}
                <PhotosCta className="ml-4" />

              </button>
              {booking === true &&
                <div className="hidden col-span-12 lg:block">
                  <BookingSearch />
                </div>
              }
            </div>
          </div>
        )}
      </section>
    </>
  )
}

GalleryDesktop.propTypes = {
  gallery: PropTypes.object,
  bgImg: PropTypes.object,
}

GalleryDesktop.defaultProps = {
  gallery: {},
  heading: false,
  bgImg: null,
}

export default GalleryDesktop
